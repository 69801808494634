@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#header-container,
#footer-container,
#body-container {
  margin-left: 2em;
  margin-right: 2em;
  color: #E1E2E2;
}

#header-container,
#footer-container {
  margin-top: 2em;
  margin-bottom: 2em;
}


#root-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  color: #FB8122;
}

@media (min-width: 1181px) {
  #app-container {
    font-size: 12px;
  }
}

@media (max-width: 1180px) {
  #app-container {
    font-size: 10px;
  }
}

@media (max-width: 568px) {
  #app-container {
    font-size: 5px;
  }
}

#app-container {
  display: flex;
  flex-direction: column;
  border: solid 1px #E1E2E2;
  margin-top: 5%;
 
}

#header-title {
  border-bottom: 0.1em solid;
  margin-top: 1em;
  margin-bottom: 1em;
}

#body-container {
  display: flex;
  flex-direction: column;
}

#main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#game-cabinet-screen-container{
  width: 30em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: auto;
  margin-top: 1.3em;
  margin-left: 1em;
  margin-right: 1em;
  border: solid 1px #E1E2E2;
}

#portable-device-controls {
  display: none;
}

.portable-device-controller-button {
  width: 10em;
  height: 10em;
  border: solid 1px #E1E2E2;
  background-color: #1D2228;
  color:#E1E2E2;
  touch-action: none;
  margin-left: 1em;
  border-radius: 10px;
}

.portable-device-controller-button:active {
  color:#1D2228;
  background-color:#E1E2E2;
}

.portable-device-controller-button-icon {
  font-size: 5em;
}

#portable-device-controller-fire-button {
    margin-right: 0.5rem;
}

@media (max-width: 840px) {
  #portable-device-controls {
    margin-top: 2em;
    display: flex;
    flex-direction: row;  
    border-radius: 1em;
  }

  #fire-start-controls {
    margin-left: auto;
    border-radius: 1em;
  }

@media (max-width: 568px) {
  .portable-device-controller-button {
    margin-left: auto;
    margin-right: 0.5rem;
  }
}

#direction-controls {
  display: flex;
  flex-direction: row;
  }
}

#footer-container {
  background-color: coral;
}

@media (max-width: 900px) {
  #cpu-state-table-container {
    display: none;
  }
}

#cpu-state-table-container {
  flex-direction: column;
  align-items: center;
}


#control-panel-container {
  text-align: center;
  margin-top: 1.2em;
  margin-left: 1em;
  margin-right: 1em;
}

@media (max-width: 568px) {
  #control-panel-container {
    margin-left: auto;
  }
}

#control-panel-status-card {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
  border-radius: 0.5em;
  }

#control-panel-disable-trace-checkbox {
  margin-left: auto;
  margin-right: auto;
  box-shadow: none;
  outline: none;
  color:#E1E2E2;
  background-color:#1D2228;
  height: 3em;
  padding-top: 0.9em;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  margin-top: 1em;
}

@media (max-width: 568px) {
  #control-panel-disable-trace-checkbox {
    margin-bottom: 2em;
  }
}

#instruction-card-container {
  font-size: 1em;
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

@media (max-width: 820px) {
  #trace-container {
    padding: 0;
    margin-left: 5em;
    width: 1em;
  }
}

#trace-container {
  font-family: courier;
  white-space: pre;
  width: 21.5em;
  margin-right: 1em;
  margin-left: 1em;
}

#trace-table td {
  height: 43.5em;
  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  #instructions-table {
    display: none;
  }
}

#instructions-table {
  border-collapse: collapse;
  border-style: solid;
  border-width: 1px;
  border-color: #E1E2E2;
  margin-bottom: 0.5em;
  width: 100%;
}

#instructions-table caption {
  padding-bottom: 0.5em;;
}

#instructions-table thead {
  border-style: solid;
  border-width: 1px;
  border-color: #E1E2E2;
}

#instructions-table th {
  border-style: solid;
  border-width: 1px;
  border-color: #E1E2E2;
  text-align: center;
}

#instructions-table td {
  text-align: center;
  border-left: 0; 
}

.control-panel-button {
  box-shadow: none;
  outline: none;
  color:#E1E2E2;
  background-color:#1D2228;
  height: 5em;
  border-style: solid;
  border-color: #E1E2E2;
  margin-bottom: 1.5em;
  border-radius: 0.5em;
  transition: 0.3s;
}

.control-panel-button:enabled:hover {
  color:#1D2228;
  background-color:#E1E2E2;
  cursor:pointer;
}

.control-panel-button:disabled {
  opacity: 0.5;
  cursor:not-allowed;
}

.greyed-out {
  opacity: 0.5;
}

.scrollable {
  height: 43.5em;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #181a20;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #E1E2E2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.diag-table-container {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 5em;
}

.diag-table {
  font-family: courier;
  font-size: 1em;
  border-collapse: collapse;
}

.diag-table th, td {
  border: 0.01em solid #999;
  padding-left: 0.3em;
  padding-right: 0.3em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.diag-table th, tfoot {
  text-align: center;
}

.diag-table td {
  color: white;
  text-align: left;
}



